import { ReqMethod, fetchHandler } from "../index";
import { User } from "../users";

export enum LessonBlockType {
  LearningMaterial = "learning-material",
  Video = "video",
  HomeWork = "homework",
  Test = "test",
}
export type LessonBlock = {
  _id: string;
  name: string;
  content: string;
  type: LessonBlockType;
};
export type Lesson = {
  _id: string;
  name: string;
  open?: boolean;
  courseBlockId?: string;
  lessonBlocks: LessonBlock[];
};

export type CourseBlock = {
  _id: string;
  name: string;
  courseLessons: Lesson[];
};

export type Course = {
  _id: string;
  name: string;
  description: string;
  features: string[];
  image: string;
  price: number;
  courseBlocks: CourseBlock[];
};
export type Resource = {
  _id: string;
  title: string;
  text: string;
};
type CreateLessonBlockValues = {
  type: LessonBlockType;
  name: string;
};
type CreateLessonBlockParams = {
  courseId: string;
  blockId: string;
  lessonId: string;
  values: CreateLessonBlockValues;
};
type UpdateLessonBlockParams = {
  courseId: string;
  blockId: string;
  lessonId: string;
  lessonBlockId: string;
  content: string;
};
type RemoveLessonBlockParams = {
  courseId: string;
  blockId: string;
  lessonId: string;
  lessonBlockId: string;
};

export enum HomeWorkStatus {
  Aproved = "approved",
  Rejected = "rejected",
  Submitted = "submitted",
  Calovyi = "calovyi",
}
export type HomeWork = {
  _id: string;
  studyFlow: string;
  user: User;
  mentor: User | null;
  createdAt: string;
  status: HomeWorkStatus;
  courseLesson: Lesson;
  userComment?: string;
  courseBlock: string;
  mentorComment?: string;
  filePaths?: string[];
};

export type Grade = {
  grade: number;
  lessonBlock: string;
  user: string;
  _id: string;
};

export const createCourse = async (values: FormData) => {
  await fetchHandler(`/courses`, {
    method: ReqMethod.POST,
    body: values,
  });
};
export const updateCourse = async (id: string, values: FormData) => {
  await fetchHandler(`/courses/${id}`, {
    method: ReqMethod.PATCH,
    body: values,
  });
};

export const removeCourse = async (id: string) => {
  await fetchHandler(`/courses/${id}`, {
    method: ReqMethod.DELETE,
  });
};

export const getCourses = async (): Promise<Course[]> =>
  fetchHandler(`/courses`, {
    method: ReqMethod.GET,
  });

export const getCourse = async (id: string): Promise<Course> =>
  fetchHandler(`/courses/${id}`, {
    method: ReqMethod.GET,
  });

export const createCourseBlock = async (id: string, name: string) => {
  await fetchHandler(`/courses/${id}/course-blocks`, {
    method: ReqMethod.POST,
    body: { name },
  });
};

export const updateCourseBlock = async (
  courseId: string,
  id: string,
  name: string,
) => {
  await fetchHandler(`/courses/${courseId}/course-blocks/${id}`, {
    method: ReqMethod.PATCH,
    body: { name },
  });
};

export const removeCourseBlock = async (
  courseId: string,
  courseBlockId: string,
) => {
  await fetchHandler(`/courses/${courseId}/course-blocks/${courseBlockId}`, {
    method: ReqMethod.DELETE,
  });
};

export const getCourseBlocks = async (id: string): Promise<CourseBlock[]> =>
  fetchHandler(`/courses/${id}/course-blocks`, {
    method: ReqMethod.GET,
  });

export const getCourseResources = async (id: string): Promise<Resource[]> =>
  fetchHandler(`/study-flows/${id}/resources`, {
    method: ReqMethod.GET,
  });

export const createCourseResource = async (
  id: string,
  title: string,
  text: string,
): Promise<Lesson> =>
  fetchHandler(`/study-flows/${id}/resources`, {
    method: ReqMethod.POST,
    body: { title, text },
  });

export const updateCourseResource = async (
  courseId: string,
  resorceId: string,
  title: string,
  text: string,
) => {
  await fetchHandler(`/study-flows/${courseId}/resources/${resorceId}`, {
    method: ReqMethod.PATCH,
    body: { title, text },
  });
};

export const removeCourseResource = async (
  courseId: string,
  resorceId: string,
) => {
  await fetchHandler(`/study-flows/${courseId}/resources/${resorceId}`, {
    method: ReqMethod.DELETE,
  });
};

export const createCourseLesson = async (
  id: string,
  blockId: string,
  name: string,
): Promise<Lesson> =>
  fetchHandler(`/courses/${id}/course-blocks/${blockId}/course-lessons`, {
    method: ReqMethod.POST,
    body: { name },
  });

export const updateCourseLesson = async (
  courseId: string,
  blockId: string,
  lessonId: string,
  name: string,
): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}`,
    {
      method: ReqMethod.PATCH,
      body: { name },
    },
  );

export const removeCourseLesson = async (
  id: string,
  blockId: string,
  lessonId: string,
) => {
  await fetchHandler(
    `/courses/${id}/course-blocks/${blockId}/course-lessons/${lessonId}`,
    {
      method: ReqMethod.DELETE,
    },
  );
};
export const getCourseLesson = async (
  id: string,
  blockId: string,
  lessonId: string,
): Promise<Lesson> =>
  await fetchHandler(
    `/courses/${id}/course-blocks/${blockId}/course-lessons/${lessonId}`,
    {
      method: ReqMethod.GET,
    },
  );

export const getCourseLessonWithStudyFlow = async (
  id: string,
  blockId: string,
  lessonId: string,
  studyFlowId: string,
): Promise<Lesson> =>
  await fetchHandler(
    `/courses/${id}/study-flow/${studyFlowId}/course-blocks/${blockId}/course-lessons/${lessonId}`,
    {
      method: ReqMethod.GET,
    },
  );
export const createLessonBlock = async ({
  courseId,
  blockId,
  lessonId,
  values,
}: CreateLessonBlockParams): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}/lesson-blocks`,
    {
      method: ReqMethod.POST,
      body: values,
    },
  );

export const updateLessonBlock = async ({
  courseId,
  blockId,
  lessonId,
  lessonBlockId,
  content,
}: UpdateLessonBlockParams): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}/lesson-blocks/${lessonBlockId}`,
    {
      method: ReqMethod.PATCH,
      body: { content },
    },
  );

export const removeLessonBlock = async ({
  courseId,
  blockId,
  lessonId,
  lessonBlockId,
}: RemoveLessonBlockParams): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}/lesson-blocks/${lessonBlockId}`,
    {
      method: ReqMethod.DELETE,
    },
  );

export const getUserHomeworks = async (
  lessonId: string,
  studyFlowId: string,
): Promise<HomeWork[]> =>
  fetchHandler(
    `/course-lessons/${lessonId}/study-flow/${studyFlowId}/user-homeworks`,
    {
      method: ReqMethod.GET,
    },
  );
export const updateHomework = async (
  lessonId: string,
  homeworkId: string,
  status: HomeWorkStatus.Aproved | HomeWorkStatus.Rejected,
  mentorComment: string,
): Promise<HomeWork[]> =>
  fetchHandler(`/course-lessons/${lessonId}/user-homeworks/${homeworkId}`, {
    method: ReqMethod.PUT,
    body: { status, mentorComment },
  });

export const createLessonGrade = async (
  lessonBlockId: string,
  userId: string,
  grade: number,
): Promise<HomeWork[]> =>
  fetchHandler(`/grades/lesson/${lessonBlockId}`, {
    method: ReqMethod.POST,
    body: { userId, grade },
  });

export const getLessonGrade = async (
  lessonBlockId: string,
  userId: string,
): Promise<Grade> =>
  fetchHandler(`/grades/lesson/${lessonBlockId}/users/${userId}`, {
    method: ReqMethod.GET,
  });
